<template>
<div class="paper-wrapper">
    <div class="paper-wrapper__title">
<!--        <h3>华中师范大学海南附属中学</h3>-->
        <h3>2020-2021 学年度第二学期</h3>
        <h3>外研版小学三年级英语其中检测试题</h3>
        <span>（三年级下册Module 1-Module 5）</span>
    </div>
    <div class="paper-wrapper__content listen">
        <h3>
            听力部分
            <span>（ 共40分 ）</span>
        </h3>
        <div class="topic">
            <div class="topic-title">
                <div class="topic-title__detail">
                    <span class="topic-title__detail--num">I.</span>
                    <span class="topic-title__detail--content">听辨单词。听录音，从A、B、C中选出你所听到的单词。每个单词读两遍</span>
                    <span class="topic-title__detail--tip">（ 每小题 2 分，共 10 分 ）</span>
                </div>
                <img src="../../assets/images/study/star.png" class="topic-title__img"  alt=""/>
            </div>
            <div class="topic-content">
                <ul class="topic-content__list">
                    <template v-for="(item, index) in itemList1">
                        <li :key="index">
                            <span class="list-num same-num">{{ item.num }}</span>
                            <el-radio v-model="item.radio" v-for="(item2, index2) in item.choose" :key="index2" :label="index2 + 1">
                                <span class="list-title same-title">{{ item2.title }}</span>
                                <span class="list-answer same-answer">{{ item2.answer }}</span>
                            </el-radio>
                        </li>
                    </template>
                </ul>
            </div>
        </div>
        <div class="topic">
            <div class="topic-title">
                <div class="topic-title__detail">
                    <span class="topic-title__detail--num">II.</span>
                    <span class="topic-title__detail--content">听录音，根据你所听到的句子，选择正确的图片。每个句子读两遍</span>
                    <span class="topic-title__detail--tip">（ 每小题 2 分，共 10 分 ）</span>
                </div>
                <img src="../../assets/images/study/stars.png" class="topic-title__img"  alt=""/>
            </div>
            <div class="topic-content">
                <div class="topic-content__img">
                    <ul class="img-list">
                        <template v-for="(item, index) in imgList1">
                            <li :key="index">
                                <img :src="item.src" alt="">
                                <span>{{ item.item }}</span>
                            </li>
                        </template>
                    </ul>
                </div>
                <div class="topic-content__answer">
                    <ul class="answer-list">
                        <template v-for="(item, index) in answerList1">
                            <li :key="index">
                                <span>{{ item.num }}</span>
                                <input type="text" v-model="item.answer">
                            </li>
                        </template>
                    </ul>
                </div>
            </div>
        </div>
        <div class="topic">
            <div class="topic-title">
                <div class="topic-title__detail">
                    <span class="topic-title__detail--num">III.</span>
                    <span class="topic-title__detail--content">听录音，根据你所听到的句子，选出恰当的答语。每个单词读两遍</span>
                    <span class="topic-title__detail--tip">（ 每小题 2 分，共 10 分 ）</span>
                </div>
                <img src="../../assets/images/study/star.png" class="topic-title__img"  alt=""/>
            </div>
            <div class="topic-content">
                <ul class="topic-content__list">
                    <template v-for="(item, index) in itemList2">
                        <li :key="index">
                            <span class="list-num same-num">{{ item.num }}</span>
                            <el-radio v-model="item.radio" v-for="(item2, index2) in item.choose" :key="index2" :label="index2 + 1">
                                <span class="list-title same-title">{{ item2.title }}</span>
                                <span class="list-answer same-answer">{{ item2.answer }}</span>
                            </el-radio>
                        </li>
                    </template>
                </ul>
            </div>
        </div>
        <div class="topic">
            <div class="topic-title">
                <div class="topic-title__detail">
                    <span class="topic-title__detail--num">IV.</span>
                    <span class="topic-title__detail--content">听录音，根据你所听到的小短文，判断下列句子的正误。正确的在括号内填“√”，错误的在括号内填“×”。小短文读两遍</span>
                    <span class="topic-title__detail--tip">（ 每小题 2 分，共 10 分 ）</span>
                </div>
                <img src="../../assets/images/study/star.png" class="topic-title__img"  alt=""/>
            </div>
        </div>
    </div>
    <div class="paper-wrapper__content write">
        <h3>
            笔试部分
            <span>（ 共60分 ）</span>
        </h3>
        <div class="topic">
            <div class="topic-title">
                <div class="topic-title__detail">
                    <span class="topic-title__detail--num">V.</span>
                    <span class="topic-title__detail--content">听录音，根据你所听到的句子，选出恰当的答语。每个单词读两遍</span>
                    <span class="topic-title__detail--tip">（ 每小题 2 分，共 10 分 ）</span>
                </div>
                <img src="../../assets/images/study/star.png" class="topic-title__img"  alt=""/>
            </div>
            <div class="topic-content">
                <ul class="topic-content__list">
                    <template v-for="(item, index) in itemList3">
                        <li :key="index">
                            <span class="list-num same-num">{{ item.num }}</span>
                            <el-radio v-model="item.radio" v-for="(item2, index2) in item.choose" :key="index2" :label="index2 + 1">
                                <span class="list-title same-title">{{ item2.title }}</span>
                                <span class="list-answer same-answer">{{ item2.answer }}</span>
                            </el-radio>
                        </li>
                    </template>
                </ul>
            </div>
        </div>
        <div class="topic">
            <div class="topic-title">
                <div class="topic-title__detail">
                    <span class="topic-title__detail--num">VI.</span>
                    <span class="topic-title__detail--content">单项选择。请选择正确的答案</span>
                    <span class="topic-title__detail--tip">（ 每小题 1 分，共 10 分 ）</span>
                </div>
                <img src="../../assets/images/study/star.png" class="topic-title__img"  alt=""/>
            </div>
            <div class="topic-content__body">
                <template v-for="(item, index) in itemList4">
                    <div class="body-title" :key="item.num">
                        <span class="same-num">{{ item.num }}</span><span>{{ item.question }}</span>
                        <span style="display:block; padding-left: 30px;">{{ item.question2 }}</span>
                    </div>
                    <ul class="body-list1" :key="index">
                        <li>
                            <el-radio v-model="item.radio"  v-for="(item1, index1) in item.choose" :key="index1" :label="index1 + 1">
                                <span class="same-title">{{ item1.title }}</span>
                                <span class="same-answer">{{ item1.answer }}</span>
                            </el-radio>
                        </li>
                    </ul>
                </template>
            </div>
        </div>
        <div class="topic">
            <div class="topic-title">
                <div class="topic-title__detail">
                    <span class="topic-title__detail--num">VII.</span>
                    <span class="topic-title__detail--content">选择方框内的单词或词组完成下列句子，每个只限用一次</span>
                    <span class="topic-title__detail--tip">（ 每小题 2 分，共 10 分 ）</span>
                </div>
                <img src="../../assets/images/study/star.png" class="topic-title__img"  alt=""/>
            </div>
            <div class="topic-content">
                <div class="topic-content__contain">
                    <ul>
                        <template v-for="(item, index) in wordList">
                            <li :key="index">
                                <span style="padding-right: 5px;">{{ item.key }}</span>
                                <span>{{ item.val }}</span>
                            </li>
                        </template>
                    </ul>
                </div>
                <div class="topic-content__body">
                    <template v-for="(item, index) in itemList5">
                        <div class="body-title" :key="item.num">
                            <span class="same-num">{{ item.num }}</span><span>{{ item.question }}</span>
                            <span style="display:block; padding-left: 30px;">{{ item.question2 }}</span>
                        </div>
                        <ul class="body-list1" :key="index">
                            <li>
                                <el-radio v-model="item.radio"  v-for="(item1, index1) in wordList" :key="index1" :label="index1 + 1">
                                    <span class="same-title">{{ item1.key }}</span>
                                    <span class="same-answer">{{ item1.val }}</span>
                                </el-radio>
                            </li>
                        </ul>
                    </template>
                </div>
            </div>
        </div>
        <div class="topic">
            <div class="topic-title">
                <div class="topic-title__detail">
                    <span class="topic-title__detail--num">VIII.</span>
                    <span class="topic-title__detail--content">情景交际。根据对话内容，选择方框内句子完成下列对话。每个句子只能使用一次。</span>
                    <span class="topic-title__detail--tip">（ 每小题 2 分，共 10 分 ）</span>
                </div>
                <img src="../../assets/images/study/star.png" class="topic-title__img"  alt=""/>
            </div>
            <div class="topic-content__wrapper">
                <div class="topic-content__box">
                    <p class="topic-content__article">A：Good morning, Jack.</p>
                    <p class="topic-content__article">B：__41__</p>
                    <p class="topic-content__article">A: How are you?</p>
                    <p class="topic-content__article">B: __42__ And you?</p>
                    <p class="topic-content__article">A: I'm fine, too.</p>
                    <p class="topic-content__article">B: What's that in your hand?</p>
                    <p class="topic-content__article">A: __43__?</p>
                    <p class="topic-content__article">B: Let me have a look.</p>
                    <p class="topic-content__article">A: OK. Here you are.</p>
                    <p class="topic-content__article">B: __44__</p>
                    <p class="topic-content__article">A: He's twelve years old.</p>
                    <p class="topic-content__article">B: He's tall. Does the like playing basketball?</p>
                    <p class="topic-content__article">A: Yes, he does. __45__ What about you?</p>
                    <p class="topic-content__article">B: I like basketball, too.</p>
                </div>
                <div class="topic-content__answer--box">
                <ul>
                    <template v-for="(item, index) in itemList6">
                        <li :key="index">
                            <span class="same-title">{{ item.title }}</span>
                            <span class="same-answer">{{ item.answer }}</span>
                        </li>
                    </template>
                </ul>
            </div>
            </div>
            <div class="topic-content">
                <ul class="topic-content__list2">
                    <template v-for="(item, index) in itemList7">
                        <li :key="index">
                            <span class="list-num same-num" style="font-size: 24px;">{{ item.num }}</span>
                            <el-radio v-model="item.radio" v-for="(item2, index2) in itemList6" :key="index2" :label="index2 + 1">
                                <span class="list-title same-title">{{ item2.title }}</span>
                                <span class="list-answer same-answer">{{ item2.answer }}</span>
                            </el-radio>
                        </li>
                    </template>
                </ul>
            </div>
        </div>
        <div class="topic">
            <div class="topic-title">
                <div class="topic-title__detail">
                    <span class="topic-title__detail--num">IX.</span>
                    <span class="topic-title__detail--content">阅读理解。阅读下面材料，选择正确答案。</span>
                    <span class="topic-title__detail--tip">（ 每小题 2 分，共 10 分 ）</span>
                </div>
                <img src="../../assets/images/study/star.png" class="topic-title__img"  alt=""/>
            </div>
            <div class="topic-content__box">
                <p class="topic-content__article">
                    There was a young painter who made a living by painting portraits （肖像）． One day， a rich man asked him to paint a portrait for him and promised to pay him 10，000 dollars．
                    After a week， the rich man came to take the painting， but he was not pleased with it． He told the young painter，"I will only pay 3，000 dollars for this painting．"
                    The young painter was angry． He argued with the rich man， hoping the rich man would keep his word．
                    The rich man thought． "The man in the painting is me． If I don't buy this painting． no one else will buy it．" So he said，"I will ask you one last time： will you sell it for 3，000 dollars？"
                    The young painter said firmly （坚定地），"No． This is a shame for me! I would prefer not to sell it， rather than be humiliated by you． Someday， you will pay 20 times the cost for breaking your promise．"
                    After that， the painter worked hard day and night． Finally， he became well﹣known in the art world．
                    The rich man forgot about the artist and what he had told him． But one day， some friends told him，"There is a painting selling for 200，000 dollars and the man in the painting looks exactly like you． It has a name of"Thief （贼）"．
                    Then， the rich man remembered the artist． He quickly went to say sorry and paid 200，000 dollars to buy the portrait． The young painter made the rich man lower his head in the end．
                </p>
            </div>
            <div class="topic-content__body">
                <template v-for="(item, index) in itemList8">
                    <div class="body-title" :key="item.num">
                        <span class="same-num">{{ item.num }}</span><span>{{ item.question }}</span>
                    </div>
                    <ul class="body-list2" :key="index">
                        <li>
                            <el-radio v-model="item.radio"  v-for="(item1, index1) in item.choose" :key="index1" :label="index1 + 1">
                                <span class="same-title">{{ item1.title }}</span>
                                <span class="same-answer">{{ item1.answer }}</span>
                            </el-radio>
                        </li>
                    </ul>
                </template>
            </div>
        </div>
        <div class="topic">
            <div class="topic-title">
                <div class="topic-title__detail">
                    <span class="topic-title__detail--num">X.</span>
                    <span class="topic-title__detail--content">仿写句子。假如你是Meimei，请你模仿上面表格 Xiaoyong 的自我介绍。并根据下表格的信息提示，简单介绍一下自己。</span>
                    <span class="topic-title__detail--tip">（ 共 10 分 ）</span>
                </div>
                <img src="../../assets/images/study/star.png" class="topic-title__img"  alt=""/>
            </div>
            <div class="topic-content">
                <table>

                </table>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: "GradeSchool",
    data() {
        return {
            imgList1: [
                {
                    src: require('../../assets/images/study/shandian.png'),
                    item: 'A'
                },
                {
                    src: require('../../assets/images/study/shandian.png'),
                    item: 'B'
                },
                {
                    src: require('../../assets/images/study/shandian.png'),
                    item: 'C'
                },
                {
                    src: require('../../assets/images/study/shandian.png'),
                    item: 'D'
                },
                {
                    src: require('../../assets/images/study/shandian.png'),
                    item: 'E'
                },
            ],
            answerList1: [
                {
                    num: '1.',
                    answer: ''
                },
                {
                    num: '2.',
                    answer: ''
                },
                {
                    num: '3.',
                    answer: ''
                },
                {
                    num: '4.',
                    answer: ''
                },
                {
                    num: '5.',
                    answer: ''
                },
            ],
            wordList: [
                {
                    key: 'A.',
                    val: 'thin'
                },
                {
                    key: 'B.',
                    val: 'friend'
                },
                {
                    key: 'C.',
                    val: 'play'
                },
                {
                    key: 'D.',
                    val: 'go to work'
                },
                {
                    key: 'E.',
                    val: 'at home'
                },
            ],
            itemList1: [
                {
                    radio: 0,
                    num: '1.',
                    choose: [
                        {
                            title: 'A.',
                            answer: 'big'
                        },
                        {
                            title: 'B.',
                            answer: 'bag'
                        },
                        {
                            title: 'C.',
                            answer: 'boy'
                        },
                    ]
                },
                {
                    radio: 0,
                    num: '2.',
                    choose: [
                        {
                            title: 'A.',
                            answer: 'big'
                        },
                        {
                            title: 'B.',
                            answer: 'bag'
                        },
                        {
                            title: 'C.',
                            answer: 'boy'
                        },
                    ]
                },
                {
                    radio: 0,
                    num: '3.',
                    choose: [
                        {
                            title: 'A.',
                            answer: 'big'
                        },
                        {
                            title: 'B.',
                            answer: 'bag'
                        },
                        {
                            title: 'C.',
                            answer: 'boy'
                        },
                    ]
                },
                {
                    radio: 0,
                    num: '4.',
                    choose: [
                        {
                            title: 'A.',
                            answer: 'big'
                        },
                        {
                            title: 'B.',
                            answer: 'bag'
                        },
                        {
                            title: 'C.',
                            answer: 'boy'
                        },
                    ]
                },
                {
                    radio: 0,
                    num: '5.',
                    choose: [
                        {
                            title: 'A.',
                            answer: 'big'
                        },
                        {
                            title: 'B.',
                            answer: 'bag'
                        },
                        {
                            title: 'C.',
                            answer: 'boy'
                        },
                    ]
                },
            ],
            itemList2: [
                {
                    radio: 0,
                    num: '11.',
                    choose: [
                        {
                            title: 'A.',
                            answer: 'big'
                        },
                        {
                            title: 'B.',
                            answer: 'bag'
                        },
                        {
                            title: 'C.',
                            answer: 'boy'
                        },
                    ]
                },
                {
                    radio: 0,
                    num: '12.',
                    choose: [
                        {
                            title: 'A.',
                            answer: 'big'
                        },
                        {
                            title: 'B.',
                            answer: 'bag'
                        },
                        {
                            title: 'C.',
                            answer: 'boy'
                        },
                    ]
                },
                {
                    radio: 0,
                    num: '13.',
                    choose: [
                        {
                            title: 'A.',
                            answer: 'big'
                        },
                        {
                            title: 'B.',
                            answer: 'bag'
                        },
                        {
                            title: 'C.',
                            answer: 'boy'
                        },
                    ]
                },
                {
                    radio: 0,
                    num: '14.',
                    choose: [
                        {
                            title: 'A.',
                            answer: 'big'
                        },
                        {
                            title: 'B.',
                            answer: 'bag'
                        },
                        {
                            title: 'C.',
                            answer: 'boy'
                        },
                    ]
                },
                {
                    radio: 0,
                    num: '15.',
                    choose: [
                        {
                            title: 'A.',
                            answer: 'big'
                        },
                        {
                            title: 'B.',
                            answer: 'bag'
                        },
                        {
                            title: 'C.',
                            answer: 'boy'
                        },
                    ]
                },
            ],
            itemList3: [
                {
                    radio: 0,
                    num: '21.',
                    choose: [
                        {
                            title: 'A.',
                            answer: 'big'
                        },
                        {
                            title: 'B.',
                            answer: 'bag'
                        },
                        {
                            title: 'C.',
                            answer: 'boy'
                        },
                    ]
                },
                {
                    radio: 0,
                    num: '22.',
                    choose: [
                        {
                            title: 'A.',
                            answer: 'big'
                        },
                        {
                            title: 'B.',
                            answer: 'bag'
                        },
                        {
                            title: 'C.',
                            answer: 'boy'
                        },
                    ]
                },
                {
                    radio: 0,
                    num: '23.',
                    choose: [
                        {
                            title: 'A.',
                            answer: 'big'
                        },
                        {
                            title: 'B.',
                            answer: 'bag'
                        },
                        {
                            title: 'C.',
                            answer: 'boy'
                        },
                    ]
                },
                {
                    radio: 0,
                    num: '24.',
                    choose: [
                        {
                            title: 'A.',
                            answer: 'big'
                        },
                        {
                            title: 'B.',
                            answer: 'bag'
                        },
                        {
                            title: 'C.',
                            answer: 'boy'
                        },
                    ]
                },
                {
                    radio: 0,
                    num: '25.',
                    choose: [
                        {
                            title: 'A.',
                            answer: 'big'
                        },
                        {
                            title: 'B.',
                            answer: 'bag'
                        },
                        {
                            title: 'C.',
                            answer: 'boy'
                        },
                    ]
                },
            ],
            itemList4: [
                {
                    num: '26.',
                    question: 'What test does Mary have today!',
                    choose: [
                        {
                            radio: 0,
                            title: 'A.',
                            answer: 'A physics test.'
                        },
                        {
                            radio: 0,
                            title: 'B.',
                            answer: 'A physics test.'
                        },
                        {
                            radio: 0,
                            title: 'C.',
                            answer: 'A physics test.'
                        }
                    ]
                },
                {
                    num: '27.',
                    question: 'What test does Mary have today!',
                    choose: [
                        {
                            radio: 0,
                            title: 'A.',
                            answer: 'A physics test.'
                        },
                        {
                            radio: 0,
                            title: 'B.',
                            answer: 'A physics test.'
                        },
                        {
                            radio: 0,
                            title: 'C.',
                            answer: 'A physics test.'
                        }
                    ]
                },
                {
                    num: '28.',
                    question: 'What test does Mary have today!',
                    choose: [
                        {
                            radio: 0,
                            title: 'A.',
                            answer: 'A physics test.'
                        },
                        {
                            radio: 0,
                            title: 'B.',
                            answer: 'A physics test.'
                        },
                        {
                            radio: 0,
                            title: 'C.',
                            answer: 'A physics test.'
                        }
                    ]
                },
                {
                    num: '29.',
                    question: 'What test does Mary have today!',
                    choose: [
                        {
                            radio: 0,
                            title: 'A.',
                            answer: 'A physics test.'
                        },
                        {
                            radio: 0,
                            title: 'B.',
                            answer: 'A physics test.'
                        },
                        {
                            radio: 0,
                            title: 'C.',
                            answer: 'A physics test.'
                        }
                    ]
                },
                {
                    num: '30.',
                    question: 'What test does Mary have today!',
                    choose: [
                        {
                            radio: 0,
                            title: 'A.',
                            answer: 'A physics test.'
                        },
                        {
                            radio: 0,
                            title: 'B.',
                            answer: 'A physics test.'
                        },
                        {
                            radio: 0,
                            title: 'C.',
                            answer: 'A physics test.'
                        }
                    ]
                },
                {
                    num: '31.',
                    question: 'What test does Mary have today!',
                    choose: [
                        {
                            radio: 0,
                            title: 'A.',
                            answer: 'A physics test.'
                        },
                        {
                            radio: 0,
                            title: 'B.',
                            answer: 'A physics test.'
                        },
                        {
                            radio: 0,
                            title: 'C.',
                            answer: 'A physics test.'
                        }
                    ]
                },
                {
                    num: '32.',
                    question: 'What test does Mary have today!',
                    choose: [
                        {
                            radio: 0,
                            title: 'A.',
                            answer: 'A physics test.'
                        },
                        {
                            radio: 0,
                            title: 'B.',
                            answer: 'A physics test.'
                        },
                        {
                            radio: 0,
                            title: 'C.',
                            answer: 'A physics test.'
                        }
                    ]
                },
                {
                    num: '33.',
                    question: 'What test does Mary have today!',
                    choose: [
                        {
                            radio: 0,
                            title: 'A.',
                            answer: 'A physics test.'
                        },
                        {
                            radio: 0,
                            title: 'B.',
                            answer: 'A physics test.'
                        },
                        {
                            radio: 0,
                            title: 'C.',
                            answer: 'A physics test.'
                        }
                    ]
                },
                {
                    num: '34.',
                    question: 'What test does Mary have today!',
                    choose: [
                        {
                            radio: 0,
                            title: 'A.',
                            answer: 'A physics test.'
                        },
                        {
                            radio: 0,
                            title: 'B.',
                            answer: 'A physics test.'
                        },
                        {
                            radio: 0,
                            title: 'C.',
                            answer: 'A physics test.'
                        }
                    ]
                },
                {
                    num: '35.',
                    question: 'What test does Mary have today!',
                    choose: [
                        {
                            radio: 0,
                            title: 'A.',
                            answer: 'A physics test.'
                        },
                        {
                            radio: 0,
                            title: 'B.',
                            answer: 'A physics test.'
                        },
                        {
                            radio: 0,
                            title: 'C.',
                            answer: 'A physics test.'
                        }
                    ]
                },
            ],
            itemList5: [
                {
                    num: '36.',
                    question: 'What test does Mary have today!',
                    radio: 0
                },
                {
                    num: '37.',
                    question: 'What test does Mary have today!',
                    radio: 0
                },
                {
                    num: '38.',
                    question: 'What test does Mary have today!',
                    radio: 0
                },
                {
                    num: '39.',
                    question: 'What test does Mary have today!',
                    radio: 0
                },
                {
                    num: '40.',
                    question: 'What test does Mary have today!',
                    radio: 0
                }
            ],
            itemList6: [
                {
                    title: 'A.',
                    answer: 'How old is he?'
                },
                {
                    title: 'B.',
                    answer: "I'm fine, thank you."
                },
                {
                    title: 'C.',
                    answer: 'Good morning, Li Lei.'
                },
                {
                    title: 'D.',
                    answer: 'I was doing my homework.'
                },
                {
                    title: 'E.',
                    answer: "It's a picture(相片) of my brother."
                }
            ],
            itemList7: [
                {
                    radio: 0,
                    num: '41.'
                },
                {
                    radio: 0,
                    num: '42.'
                },
                {
                    radio: 0,
                    num: '43.'
                },
                {
                    radio: 0,
                    num: '44.'
                },
                {
                    radio: 0,
                    num: '45.'
                },
            ],
            itemList8: [
                {
                    num: '46.',
                    question: 'What test does Mary have today!',
                    choose: [
                        {
                            radio: 0,
                            title: 'A.',
                            answer: 'A physics test.'
                        },
                        {
                            radio: 0,
                            title: 'B.',
                            answer: 'A physics test.'
                        },
                        {
                            radio: 0,
                            title: 'C.',
                            answer: 'A physics test.'
                        }
                    ]
                },
                {
                    num: '47.',
                    question: 'What test does Mary have today!',
                    choose: [
                        {
                            radio: 0,
                            title: 'A.',
                            answer: 'A physics test.'
                        },
                        {
                            radio: 0,
                            title: 'B.',
                            answer: 'A physics test.'
                        },
                        {
                            radio: 0,
                            title: 'C.',
                            answer: 'A physics test.'
                        }
                    ]
                },
                {
                    num: '48.',
                    question: 'What test does Mary have today!',
                    choose: [
                        {
                            radio: 0,
                            title: 'A.',
                            answer: 'A physics test.'
                        },
                        {
                            radio: 0,
                            title: 'B.',
                            answer: 'A physics test.'
                        },
                        {
                            radio: 0,
                            title: 'C.',
                            answer: 'A physics test.'
                        }
                    ]
                },
                {
                    num: '49.',
                    question: 'What test does Mary have today!',
                    choose: [
                        {
                            radio: 0,
                            title: 'A.',
                            answer: 'A physics test.'
                        },
                        {
                            radio: 0,
                            title: 'B.',
                            answer: 'A physics test.'
                        },
                        {
                            radio: 0,
                            title: 'C.',
                            answer: 'A physics test.'
                        }
                    ]
                },
                {
                    num: '50.',
                    question: 'What test does Mary have today!',
                    choose: [
                        {
                            radio: 0,
                            title: 'A.',
                            answer: 'A physics test.'
                        },
                        {
                            radio: 0,
                            title: 'B.',
                            answer: 'A physics test.'
                        },
                        {
                            radio: 0,
                            title: 'C.',
                            answer: 'A physics test.'
                        }
                    ]
                },
            ],
        }
    },
    created() {
        // this.getShijuan()
    },
    methods: {
        getShijuan() {
            this.axios.post('/api/exam/shijuan', {token: this.token, id: this.id}).then(res => {
                console.log('paper-item', res.data.data);
                this.examList = res.data.data.shijuan_mold;
            })
        },
    }
}
</script>

<style scoped>

</style>


